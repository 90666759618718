import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import { AppContext } from "../context";
import axios from 'axios';

export function useFactory() {
  const {
    V1Checked,
    amount,
    cronos_nodeUrl,
    walletAddress,
    Factory_ADDRESS,
    Factory_ABI,
    ptCRO_ADDRESS,
    ptCRO_ABI,
    ytCRO_ADDRESS,
    ytCRO_ABI,
    PAN_address,
    PAN_ABI,
    WCRO_address,
    web3Provider,
    web3JsonRpcProvider,
    setIsLoading,
    selectedCoin,
    setBurnFee,
    setUtilization,
    setPanHodlLevel,
    setHoldDiscount,
    setBalanceptCRO,
    setBalanceytCRO,
    setBalancePAN,
    setPendingRewards,
    setFlexibleCRO,
    //V2
    ptCROV2_ADDRESS,
    ytCROV2_ADDRESS,
    FactoryV2_ADDRESS,
    ptCROV2_ABI,
    ytCROV2_ABI,
    FactoryV2_ABI,
    setUtilizationV2,
    setBalanceptCROV2,
    setBalanceytCROV2,
    setPendingRewardsV2,
    setFlexibleCROV2,
    setBurnFeeV2
  } = useContext(AppContext);

  async function getAccountBalance() {
    //const signer = web3Provider.getSigner();
    const currentAddress = await web3Provider.getSigner().getAddress();
    //var Balance = await web3Provider.getBalance(signer.getAddress());

    const ptCRO_contract = new ethers.Contract(ptCRO_ADDRESS, ptCRO_ABI, web3Provider);
    var BalanceptCRO = await ptCRO_contract.balanceOf(currentAddress);
  
    const ytCRO_contract = new ethers.Contract(ytCRO_ADDRESS, ytCRO_ABI, web3Provider);
    var BalanceytCRO = await ytCRO_contract.balanceOf(currentAddress);
    var pendingRewards = await ytCRO_contract.getPendingRewards(currentAddress);

    const PAN_contract = new ethers.Contract(PAN_address, PAN_ABI, web3Provider);
    var BalanceytPAN = await PAN_contract.balanceOf(currentAddress);

    const WCRO_contract = new ethers.Contract(WCRO_address, ptCRO_ABI, web3Provider); //use ptCRO_ABI for ERC20 balanceOf query.
    var factoryCRO = await await WCRO_contract.balanceOf(Factory_ADDRESS);
    

    //setBalanceCRO(Number(ethers.utils.formatEther(Balance)).toFixed(3));
    setBalanceptCRO(Number(ethers.utils.formatEther(BalanceptCRO)).toFixed(3));
    setBalanceytCRO(Number(ethers.utils.formatEther(BalanceytCRO)).toFixed(3));
    setBalancePAN(Number(ethers.utils.formatEther(BalanceytPAN)).toFixed(3));
    setPendingRewards(Number(ethers.utils.formatEther(pendingRewards)).toFixed(3));
    //setFlexibleCRO(Number(ethers.utils.formatEther(factoryCRO)).toFixed(3));

    //V2

    const ptCROV2_contract = new ethers.Contract(ptCROV2_ADDRESS, ptCROV2_ABI, web3Provider);
    var BalanceptCROV2     = await ptCROV2_contract.balanceOf(currentAddress);
  
    const ytCROV2_contract = new ethers.Contract(ytCROV2_ADDRESS, ytCROV2_ABI, web3Provider);
    var BalanceytCROV2     = await ytCROV2_contract.balanceOf(currentAddress);
    var pendingRewardsV2   = await ytCROV2_contract.getPendingRewards(currentAddress);
    var factoryCROV2       = await WCRO_contract.balanceOf(FactoryV2_ADDRESS);

    setBalanceptCROV2(Number(ethers.utils.formatEther(BalanceptCROV2)).toFixed(3));
    setBalanceytCROV2(Number(ethers.utils.formatEther(BalanceytCROV2)).toFixed(3));
    setPendingRewardsV2(Number(ethers.utils.formatEther(pendingRewardsV2)).toFixed(3));
    //setFlexibleCROV2(Number(ethers.utils.formatEther(factoryCROV2)).toFixed(3));

  }

  async function getHodlLVLDiscnt() {
    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, web3Provider);
    const currentAddress = await web3Provider.getSigner().getAddress();
    
    if (currentAddress !== "") {
      var holdDiscount = await Factory_contract.getHoldDiscount(currentAddress);
      //console.log(Number(holdDiscount));
      var hodlLevel = 0;

      if      (Number(holdDiscount) === 100)
        hodlLevel = 0;
      else if (Number(holdDiscount) === 90)
        hodlLevel = 1;
      else if (Number(holdDiscount) === 75)
        hodlLevel = 2;
      else if (Number(holdDiscount) === 55)
        hodlLevel = 3;

      setHoldDiscount( (100-Number(holdDiscount)) );
      setPanHodlLevel(Number(hodlLevel));
    }
  }

  async function getUtilization() {
    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, web3Provider);
    var delegatioRatio = await Factory_contract.getDelegationRatio();
    var targetRatio    = await Factory_contract.getTargetRatio();

    delegatioRatio = Number(Number(delegatioRatio) / 1e4).toFixed(0);
    targetRatio    = Number(Number(targetRatio)    / 1e4).toFixed(0);

    var reserveRatio = (Number(targetRatio)<=0)      ? 100 : 
                       (Number(delegatioRatio)>100)  ? 100 : 100*(100-delegatioRatio) / (100-targetRatio);
    var util         = (Number(reserveRatio)>100)    ?   0 : Number(100 - reserveRatio).toFixed(0);
    setUtilization(Number(util));
    //console.log(Number(delegatioRatio), Number(targetRatio), reserveRatio);

    //V2
    const FactoryV2_contract = new ethers.Contract(FactoryV2_ADDRESS, FactoryV2_ABI, web3Provider);
    delegatioRatio = await FactoryV2_contract.getDelegationRatio();
    targetRatio    = await FactoryV2_contract.getTargetRatio();

    delegatioRatio = Number(Number(delegatioRatio) / 1e4).toFixed(0);
    targetRatio    = Number(Number(targetRatio)    / 1e4).toFixed(0);

    reserveRatio = (Number(targetRatio)<=0)      ? 100 : 
                   (Number(delegatioRatio)>100)  ? 100 : 100*(100-delegatioRatio) / (100-targetRatio);
    util         = (Number(reserveRatio)>100)    ?   0 : Number(100 - reserveRatio).toFixed(0);
    setUtilizationV2(Number(util));    
    //
  }

  async function getUtilizationFromServer() {
    //const response = await axios.get('http://localhost:3006/croapr');
    const response = await axios.get('https://app.creampan.finance/croapr');
  
    if (response.status !== 200) {
      throw new Error('Failed to fetch APR');
    }
    //console.log(response.data.poolStats);

    setUtilization  (Number(response.data.croPoolStats[1].utilization));
    setUtilizationV2(Number(response.data.croPoolStats[2].utilization));    
    //
  }


  async function getBurnFee() {
    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, web3Provider);
    var BurnFee = await Factory_contract.getCurrentFee();
    BurnFee = Number( Number(BurnFee) / 1e6 );

    setBurnFee(Number(BurnFee));
    //console.log(BurnFee);

    //V2
    const FactoryV2_contract = new ethers.Contract(FactoryV2_ADDRESS, FactoryV2_ABI, web3Provider);
    BurnFee = await FactoryV2_contract.getCurrentFee();
    BurnFee = Number( Number(BurnFee) / 1e6 );

    setBurnFeeV2(Number(BurnFee));
    //
  }

  async function getBurnFeeFromServer() {
    //const response = await axios.get('http://localhost:3006/croapr');
    const response = await axios.get('https://app.creampan.finance/croapr');
  
    if (response.status !== 200) {
      throw new Error('Failed to fetch APR');
    }
    //console.log(response.data.poolStats);

    setBurnFee  (Number(response.data.croPoolStats[1].burnFee));
    setBurnFeeV2(Number(response.data.croPoolStats[2].burnFee));    
    //
  }


  async function mint_ptCRO() {
    setIsLoading(true);

    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, web3Provider.getSigner());
    var tx;

    const gasLimit = 300000;

    try {
      if (/^\d+(\.\d+)?$/.test(amount)) {
        var mintAmount = ethers.utils.parseEther(amount);
      }
      //console.log(walletAddress, mintAmount);

      if      (selectedCoin.symbol === "CRO") {
        tx = await Factory_contract.mint(walletAddress, {value: mintAmount, gasLimit: gasLimit});
      }
      else if (selectedCoin.symbol === "WCRO") {
        tx = await Factory_contract.mintFromWCRO(walletAddress, mintAmount, {gasLimit});
      }

      //console.log(tx);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function mint_ptCROV2() {
    setIsLoading(true);

    const FactoryV2_contract = new ethers.Contract(FactoryV2_ADDRESS, FactoryV2_ABI, web3Provider.getSigner());
    var tx;

    const gasLimit = 300000;

    if (/^\d+(\.\d+)?$/.test(amount)) {
      var mintAmount = ethers.utils.parseEther(amount);
    }

    try {
      //console.log(walletAddress, mintAmount);

      if      (selectedCoin.symbol === "CRO") {
        tx = await FactoryV2_contract.mint(walletAddress, {value: mintAmount, gasLimit: gasLimit});
      }
      else if (selectedCoin.symbol === "WCRO") {
        tx = await FactoryV2_contract.mintFromWCRO(walletAddress, mintAmount, {gasLimit});
      }

      //console.log(tx);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function claimRewards() {
    setIsLoading(true);
    let ytCRO_contract;


    if (V1Checked) {
      ytCRO_contract = new ethers.Contract(ytCRO_ADDRESS  , ytCRO_ABI  , web3Provider.getSigner());
    } else {
      ytCRO_contract = new ethers.Contract(ytCROV2_ADDRESS, ytCROV2_ABI, web3Provider.getSigner());
    }

    var tx;

    try {

      if      (selectedCoin.symbol === "CRO") {
        const claimFunc = ytCRO_contract.functions['claim()'];
        tx = await claimFunc.call();
      }
      else if (selectedCoin.symbol === "WCRO") {
        const claimToWCROFunc = ytCRO_contract.functions['claimToWCRO()'];
        tx = await claimToWCROFunc.call();
      }

      //console.log(tx);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function burn_ptCRO() {
    setIsLoading(true);
    let Factory_contract;

    if (V1Checked) {
      Factory_contract = new ethers.Contract(Factory_ADDRESS  , Factory_ABI  , web3Provider.getSigner());
    } else {
      Factory_contract = new ethers.Contract(FactoryV2_ADDRESS, FactoryV2_ABI, web3Provider.getSigner());
    }
    
    var tx;

    const gasLimit = 300000;

    try {
      if (/^\d+(\.\d+)?$/.test(amount)) {
        var burnAmount = ethers.utils.parseEther(amount);
      }
      //console.log(walletAddress, burnAmount);

      if      (selectedCoin.symbol === "CRO") {
        tx = await Factory_contract.burn(burnAmount, {gasLimit});
      }
      else if (selectedCoin.symbol === "WCRO") {
        tx = await Factory_contract.burnToWCRO(burnAmount, {gasLimit});
      }

      //console.log(tx);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }


  return {
    getAccountBalance,
    getUtilization,
    getBurnFee,
    getHodlLVLDiscnt,
    mint_ptCRO,
    burn_ptCRO,
    claimRewards,
    mint_ptCROV2,
    getUtilizationFromServer,
    getBurnFeeFromServer
  };
}
