import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import Web3 from "web3";
//import { ChainId, Token, Fetcher, Route } from '@uniswap/sdk';
import { ChainId, Token, Fetcher, Route } from 'vvs-sdk';
import { AppContext } from "../context";
import axios from 'axios';

export function usePAN() {
  const {
    walletAddress,
    price_WCRO,
    MasterBaker_ADDRESS,
    MasterBaker_ABI,
    ptCRO_ADDRESS,
    ytCRO_ADDRESS,
    WCRO_address,
    PAN_address,
    USDT_address,
    web3Provider,
    web3JsonRpcProvider,
    web3Signer,
    setPricePTCRO,
    setPriceYTCRO,
    setPricePAN, 
    setPriceWCRO,
    setAprCRO,
    setAprV2CRO,
    setStakedCRO,
    setUnstakingCRO,
    //V2
    setStakedCROV2,
    setUnstakingCROV2,
    cronos_nodeUrl
  } = useContext(AppContext);

  const ChainID = 25; //MAINNET

  const UniswapV2Pair_ABI  = require("../abis/UniswapV2Pair_abi.json");
  const UniswapV2ERC20_ABI = require("../abis/UniswapV2ERC20_abi.json");

  
  async function getCROAPRFromServer() {  
    //const response = await axios.get('http://localhost:3006/croapr');
    const response = await axios.get('https://app.creampan.finance/croapr');

    //console.log(response.data.croPoolStats);

    if (response.status !== 200) {
      console.log("CROAPR error");
      throw new Error('Failed to fetch APR');
    }
    setAprCRO(Number(response.data.croPoolStats[0].apr).toFixed(2));
    setAprV2CRO(Number(response.data.croPoolStats[0].aprV2).toFixed(2));
    setStakedCRO(Number(Number(response.data.croPoolStats[1].totalStaked)/1e8).toFixed(3));
    setUnstakingCRO(Number(Number(response.data.croPoolStats[1].totalUnstaked)/1e8).toFixed(3));

    //V2
    setStakedCROV2(Number(Number(response.data.croPoolStats[2].totalStaked)/1e8).toFixed(3));
    setUnstakingCROV2(Number(Number(response.data.croPoolStats[2].totalUnstaked)/1e8).toFixed(3));
    //

    return response.data.croPoolStats; 
  }

  async function getAPRFromServer() {  
    //const response = await axios.get('http://localhost:3006/apr');
    const response = await axios.get('https://app.creampan.finance/apr');
  
    if (response.status !== 200) {
      throw new Error('Failed to fetch APR');
    }
    //console.log(response.data.poolStats);
    return response.data.poolStats; 
  }

  async function getPriceFromServer() {
    let obj = 
    {
      price_PAN:     0,
      price_WCRO:    0,
      price_ptCRO:   0,
      price_ytCRO:   0,
      price_ptCROv2: 0,
      price_ytCROv2: 0
    };

    //const response = await axios.get('http://localhost:3006/price');
    const response = await axios.get('https://app.creampan.finance/price');
  
    if (response.status !== 200) {
      throw new Error('Failed to fetch APR');
    }
    //console.log(response.data.priceStats);

    obj.price_PAN     = Number(response.data.priceStats[0].price);
    obj.price_WCRO    = Number(response.data.priceStats[1].price);
    obj.price_ptCRO   = Number(response.data.priceStats[2].price);
    obj.price_ytCRO   = Number(response.data.priceStats[3].price);
    obj.price_ptCROv2 = Number(response.data.priceStats[4].price);
    obj.price_ytCROv2 = Number(response.data.priceStats[5].price);
    //
    
    return obj;
  }

    async function checkAllowance(contractAddress, amount, spender) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Signer);
      const owner = await web3Provider.getSigner().getAddress();

      let allowedAmount;
      try {
        allowedAmount = await ERC20_contract.allowance(owner, spender);

      } catch (error) {
        console.error(error);
      }
      
      let check = Number(ethers.utils.formatEther(allowedAmount)) >= Number(amount);
      //console.log(Number(ethers.utils.formatEther(allowedAmount)).toFixed(1), check);
      return (check);
    }

    async function getTotalSupply(contractAddress) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Provider);
      let totalSupply;
      try {
        totalSupply = await ERC20_contract.totalSupply();
  
      } catch (error) {
        console.error(error);
      }

      return (Number(ethers.utils.formatEther(totalSupply)).toFixed(8));
    }

    async function getBalance(contractAddress, address) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Provider);
      //const currentAddress = await web3Provider.getSigner().getAddress();
      var balance;
      try {
        balance = await ERC20_contract.balanceOf(address);
  
      } catch (error) {
        console.error(error);
      }
      //Number(ethers.utils.formatEther(balance)).toFixed(1)
      
      
      var round = Math.floor(Number(ethers.utils.formatEther(balance))*1000)/1000;

      return (round);
    }

    async function getPTCROPrice() {
      //console.log(ChainId.TESTNET);
      const _token = new Token(ChainID, ptCRO_ADDRESS, 18);
      const WETH   = new Token(ChainID,  WCRO_address, 18);
      //console.log(_token, WETH);
      const pair = await Fetcher.fetchPairData(_token, WETH, web3Provider);
      //console.log(pair);
      const route = new Route([pair], WETH);
      //console.log(route);
      //console.log(Number(route.midPrice.invert().toSignificant(6)));
      //setPricePTCRO(Number(route.midPrice.invert().toSignificant(6))); // # of WETH per ptCRO
      //console.log(price_ptCRO);
      return (Number(route.midPrice.invert().toSignificant(6)).toFixed(3));
    }

    async function getYTCROPrice() {
      const _token = new Token(ChainID, ytCRO_ADDRESS, 18);
      const WETH   = new Token(ChainID,  WCRO_address, 18);
      const pair = await Fetcher.fetchPairData(_token, WETH, web3Provider);
      const route = new Route([pair], WETH);
      //setPriceYTCRO(Number(route.midPrice.invert().toSignificant(6))); // # of WETH per ytCRO
      //console.log(price_ytCRO);
      return (Number(route.midPrice.invert().toSignificant(6)).toFixed(3));
    }

    //V2
    async function getTokenPrice(contractAddress) {
      const web3 = new Web3(cronos_nodeUrl);
      const checksumAddress = web3.utils.toChecksumAddress(contractAddress);
      const _token = new Token(ChainID, checksumAddress, 18);
      const WETH   = new Token(ChainID,  WCRO_address, 18);
      const pair = await Fetcher.fetchPairData(_token, WETH, web3Provider);
      const route = new Route([pair], WETH);
      //console.log(Number(route.midPrice.invert().toSignificant(6)).toFixed(3));
      return (Number(route.midPrice.invert().toSignificant(6)).toFixed(3));
    }
    //

    async function getPANPrice() {
      //console.log(ChainId.TESTNET);
      const _token = new Token(ChainID,  PAN_address, 18);
      const WETH   = new Token(ChainID, WCRO_address, 18);
      //console.log(_token, WETH);
      const pair = await Fetcher.fetchPairData(_token, WETH, web3Provider);
      //console.log(pair);
      const route = new Route([pair], WETH);
      //setPricePAN(Number(route.midPrice.invert().toSignificant(6))); // # of WETH per PAN
      //console.log(price_PAN);
      return (Number(route.midPrice.invert().toSignificant(6)).toFixed(3));
    }

    async function getWCROPrice() {
      const _token = new Token(ChainID, WCRO_address, 18);
      const USDT   = new Token(ChainID,  USDT_address, 6);
      const pair = await Fetcher.fetchPairData(_token, USDT, web3Provider);
      const route = new Route([pair], USDT);
      //setPriceWCRO(Number(route.midPrice.invert().toSignificant(6))); // # of USDT per WCRO
      //console.log(route, price_WCRO);
      return (Number(route.midPrice.invert().toSignificant(6)).toFixed(3));
    }

    async function getStakeAmount(pid) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider);
      const currentAddress = await web3Provider.getSigner().getAddress();

      const userinfo = await MasterBaker_contract.userInfo(pid, currentAddress); //try catch
      //console.log(pid, Number(ethers.utils.formatEther(userinfo.amount)).toFixed(1));
      return (Number(ethers.utils.formatEther(userinfo.amount)).toFixed(1));
    }

    async function getTotalStake(pid) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider);
      const poolinfo = await MasterBaker_contract.poolInfo(pid); //try catch
      const lpToken_contract = new ethers.Contract(poolinfo.lpToken, UniswapV2Pair_ABI, web3Provider);
      var   totalStake = await lpToken_contract.balanceOf(MasterBaker_ADDRESS);

      return (Number(ethers.utils.formatEther(totalStake)).toFixed(1));
    }

    async function getRewards(pid) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider);
      const currentAddress = await web3Provider.getSigner().getAddress();

      const pendingPan = await MasterBaker_contract.pendingPan(pid, currentAddress); //try catch
      //console.log(pid, Number(ethers.utils.formatEther(pendingPan)).toFixed(1));
      return (Number(ethers.utils.formatEther(pendingPan)).toFixed(1));      
    }

    async function claimRewards(pid) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Signer);
      var tx;
      try {
        if (Number(pid) !== 0)
          tx = await MasterBaker_contract.deposit(pid, '0');
        else
          tx = await MasterBaker_contract.enterStaking('0');
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
      return (tx);
    }

    async function depositToken(pid, amount) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Signer);
      const gasLimit = 300000;
      var tx;

      if (/^\d+(\.\d+)?$/.test(amount)) {
        var stakeAmount = ethers.utils.parseEther(amount);
      }
      //console.log(pid, stakeAmount);
      try {
        tx = await MasterBaker_contract.deposit(pid, stakeAmount, {gasLimit});
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
      return (tx);
    }

    async function withdrawToken(pid, amount) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Signer);
      const gasLimit = 300000;
      var tx;

      if (/^\d+(\.\d+)?$/.test(amount)) {
        var withdrawAmount = ethers.utils.parseEther(amount);
      }
      try {
        tx = await MasterBaker_contract.withdraw(pid, withdrawAmount, {gasLimit});
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
      return (tx);
    }

    async function enterStakeToken(amount) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Signer);
      const gasLimit = 300000;
      var tx;

      if (/^\d+(\.\d+)?$/.test(amount)) {
        var stakeAmount = ethers.utils.parseEther(amount);
        //console.log(Number(stakeAmount));
      }
      try {
        tx = await MasterBaker_contract.enterStaking(stakeAmount, {gasLimit});
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
      return (tx);
    }

    async function leaveStakeToken(amount) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Signer);
      const gasLimit = 300000;
      var tx;

      if (/^\d+(\.\d+)?$/.test(amount)) {
        var withdrawAmount = ethers.utils.parseEther(amount);
      }
      try {
        tx = await MasterBaker_contract.leaveStaking(withdrawAmount, {gasLimit});
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
      return (tx);
    }

    async function approveToken(contractAddress, spender, amount) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Signer);
      var tx;
      if (/^\d+(\.\d+)?$/.test(amount)) {
        // 100 times amount
        var amount_scale = (Number(amount)*100).toString();

        var approveAmount = ethers.utils.parseEther(amount_scale);
      }
      try {
        tx = await ERC20_contract.approve(spender, approveAmount);
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
      return (tx);
    }

    async function getBalance_decimals(contractAddress, address, decimals) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Provider);
      //const currentAddress = await web3Provider.getSigner().getAddress();
      var balance;
      try {
        balance = await ERC20_contract.balanceOf(address);
  
      } catch (error) {
        console.error(error);
      }
      //Number(ethers.utils.formatEther(balance)).toFixed(1)
      
      
      var round = Math.floor(Number(ethers.utils.formatUnits(balance, decimals))*1000)/1000;

      return (round);
    }    

    async function checkAllowance_decimals(contractAddress, amount, spender, decimals) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Signer);
      const owner = await web3Provider.getSigner().getAddress();

      let allowedAmount;
      try {
        allowedAmount = await ERC20_contract.allowance(owner, spender);

      } catch (error) {
        console.error(error);
      }
      
      let check = Number(ethers.utils.formatUnits(allowedAmount, decimals)) >= Number(amount);
      //console.log(Number(ethers.utils.formatEther(allowedAmount)).toFixed(1), check);
      return (check);
    }

    async function approveToken_decimals(contractAddress, spender, amount, decimals) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Signer);
      var tx;
      if (/^\d+(\.\d+)?$/.test(amount)) {
        // 100 times amount
        var amount_scale = (Number(amount)*100).toString();

        var approveAmount = ethers.utils.parseUnits(amount_scale, decimals);


      }
      try {
        tx = await ERC20_contract.approve(spender, approveAmount);
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
      return (tx);
    }

  return {
    checkAllowance,
    getCROAPRFromServer,
    getAPRFromServer,
    getTotalSupply,
    getBalance,
    getPTCROPrice,
    getYTCROPrice,
    getPANPrice,
    getWCROPrice,
    getStakeAmount,
    getTotalStake,
    getRewards,
    claimRewards,
    depositToken,
    withdrawToken,
    enterStakeToken,
    leaveStakeToken,
    approveToken,
    getBalance_decimals,
    checkAllowance_decimals,
    approveToken_decimals,
    //V2
    getTokenPrice,
    //
    getPriceFromServer
  };
}
