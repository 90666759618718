import React, { useContext, useState, useEffect } from "react";
import { Box, Flex, Button, Text, Card, CardHeader, CardBody, CardFooter, Stack, Heading, Divider, StackDivider, useWhyDidYouUpdate, Image, Icon, useDisclosure,
         IconButton, useToast, Tooltip } from "@chakra-ui/react";
import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { isBrowser, isMobile} from "react-device-detect";
import { isIOS, isAndroid } from "react-device-detect";
import { ethers } from "ethers";
import { useWeb3 } from "./hooks/useWeb3";
import { usePAN } from "./hooks/usePAN";
import { useIcon } from "./hooks/useIcon";

import AccountModal from "./components/AccountModal";
import CustomSwitchLarge  from "./components/CustomSwitchLarge";

import axios from 'axios';
import logo_cronos from "./assets/cronos_light_bg.png";

import { AppContext } from "./context";

function Dashboard() {
  const {
    ptCRO_ADDRESS,
    ytCRO_ADDRESS,
    WCRO_address,
    ytCRO_ABI,
    Factory_ADDRESS,
    Factory_ABI,
    PAN_address,
    cronos_nodeUrl,
    web3Provider,
    balanceCRO,
    balanceWCRO,
    balanceptCRO,
    balanceytCRO,
    balancePAN,
    price_ptCRO,
    price_ytCRO,
    price_WCRO,
    price_PAN,
    pendingRewards,
    flexibleCRO,
    ytTotalSupply,
    setYtTotalSupply,
    delegationRaio,
    setDelegationRatio,
    myStakedPAN,
    setMyStakedPAN,
    myPANRewards,
    setMyPANRewards,
    blockNumber,
    color,
    color2,
    aprCRO,
    panHodlLevel,
    reserveRatio,
    setReserveRatio,
    stakedCRO,
    unstakingCRO,
    //V2
    ptCROV2_ADDRESS,
    ytCROV2_ADDRESS,
    FactoryV2_ADDRESS,
    ptCROV2_ABI,
    ytCROV2_ABI,
    FactoryV2_ABI,
    pendingRewardsV2,
    flexibleCROV2,
    ytTotalSupplyV2,
    setYtTotalSupplyV2,
    delegationRaioV2,
    setDelegationRatioV2,
    V1Checked,
    setV1Checked,
    reserveRatioV2,
    setReserveRatioV2,
    balanceptCROV2,
    balanceytCROV2,
    price_ptCROV2,
    price_ytCROV2,
    stakedCROV2,
    unstakingCROV2,
    aprV2CRO,
    setFlexibleCRO,
    setFlexibleCROV2
  } = useContext(AppContext);

  const { isOpen: isOpenStake, onOpen: onOpenStake, onClose: onCloseStake } = useDisclosure();

  const { handleCryptoOrgExplorer, handleExplorer } = useWeb3();
  const { getStakeAmount, getRewards } = usePAN();
  const { CircleIcon } = useIcon();

  const toast = useToast();

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  //V2
  const toggleSwitch = () => setV1Checked(!V1Checked);

  const handleCopyClick = (desc, text) => {
    navigator.clipboard.writeText(text);
    toast({
      title: desc + " address copied!",
      description: text,
      status: "success",
      duration: 2500,
      isClosable: true,
      containerStyle: {
        width: (isMobile) ? "96vw" : "" ,
        fontSize: (isMobile) ? "14px" : "" ,
      },
    });
  };

  const calculateTargetTime = () => {
    const now    = new Date();
    const target = new Date();
    var targetHour   = '23';
    var targetMinute = '15';

    target.setHours(targetHour, targetMinute, 0, 0); // Set to 23:15:00

    // If target time is already past, set it for the next day
    if (target <= now) {
      target.setDate(target.getDate() + 1);
    }

    return target;
  };

  const [targetTime, setTargetTime] = useState(calculateTargetTime());
  const [timeLeft, setTimeLeft] = useState('');


  async function getTotalPANRewards() {
    var totalReward=0;
    for (let i=0; i < 6; i++)
    {
      var newReward = await getRewards(i);
      //newReward     = Number(Number(newReward)*51/100).toFixed(3); //Only 51% coin is rewards

      //console.log(newReward);
      totalReward += Number(newReward);
    }
    return (Number(totalReward).toFixed(3));
  }

  async function getTotalSupply() {
    const provider = new ethers.providers.JsonRpcProvider(cronos_nodeUrl);

    const ytCRO_contract = new ethers.Contract(ytCRO_ADDRESS, ytCRO_ABI, web3Provider);
    var ytTotalSupply = await ytCRO_contract.totalSupply();

    setYtTotalSupply(Number(ethers.utils.formatEther(ytTotalSupply)).toFixed(3));

    //V2
    const ytCROV2_contract = new ethers.Contract(ytCROV2_ADDRESS, ytCROV2_ABI, provider);
        ytTotalSupply = await ytCROV2_contract.totalSupply();

    setYtTotalSupplyV2(Number(ethers.utils.formatEther(ytTotalSupply)).toFixed(3));

    //
  }

  async function getDelegationRatio() {
    const provider = new ethers.providers.JsonRpcProvider(cronos_nodeUrl);

    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, web3Provider);
    var currentDelegatioRatio = await Factory_contract.getDelegationRatio();

    setDelegationRatio(Number(Number(currentDelegatioRatio) / 1e4 ).toFixed(2));

    //V2
    const FactoryV2_contract = new ethers.Contract(FactoryV2_ADDRESS, FactoryV2_ABI, provider);
    currentDelegatioRatio = await FactoryV2_contract.getDelegationRatio();

    setDelegationRatioV2(Number(Number(currentDelegatioRatio) / 1e4 ).toFixed(2));
    //
  }

  async function getInfoFromServer() {
    //const response = await axios.get('http://localhost:3006/croapr');
    const response = await axios.get('https://app.creampan.finance/croapr');
  
    if (response.status !== 200) {
      throw new Error('Failed to fetch APR');
    }
    //console.log(response.data.croPoolStats);

    setYtTotalSupply  (Number(response.data.croPoolStats[1].totalSupplyYTCRO).toFixed(3));
    setYtTotalSupplyV2(Number(response.data.croPoolStats[2].totalSupplyYTCRO).toFixed(3));

    setDelegationRatio  (Number(response.data.croPoolStats[1].delegationRatio).toFixed(2));
    setDelegationRatioV2(Number(response.data.croPoolStats[2].delegationRatio).toFixed(2));

    setFlexibleCRO  (Number(response.data.croPoolStats[1].poolFlexibleCRO).toFixed(3));
    setFlexibleCROV2(Number(response.data.croPoolStats[2].poolFlexibleCRO).toFixed(3));

    if ( (!isNaN(response.data.croPoolStats[1].croReserveRatio)) && (Number(response.data.croPoolStats[1].croReserveRatio)<150) ) {
      setReserveRatio(response.data.croPoolStats[1].croReserveRatio);
    }
    
    //V2
    if ( (!isNaN(response.data.croPoolStats[2].croReserveRatio)) && (Number(response.data.croPoolStats[2].croReserveRatio)<150) ) {
      setReserveRatioV2(response.data.croPoolStats[2].croReserveRatio);
    }
  }

//  const [refreshInterval, setRefreshInterval] = useState(null);

  //regular update states
  useEffect(() => {
    let intervalId;
    let stakedAmount;
    let totalPANRewards;

    const updateBalance = async () => {
      if (web3Provider) {
        try {
          //await getTotalSupply();
          //await getDelegationRatio();

            //var calReserveRatio   = ( Number( Number(Number(stakedCRO)+Number(unstakingCRO)+Number(flexibleCRO))*100 / Number(ytTotalSupply)).toFixed(2) );

            //V2
            //var calReserveRatioV2 = ( Number( Number(Number(stakedCROV2)+Number(unstakingCROV2)+Number(flexibleCROV2))*100 / Number(ytTotalSupplyV2)).toFixed(2) );

            //console.log(calReserveRatio, Number(stakedCRO)  +Number(unstakingCRO)  +Number(flexibleCRO)  , Number(ytTotalSupply));
            //console.log(calReserveRatioV2, Number(stakedCROV2)+Number(unstakingCROV2)+Number(flexibleCROV2), Number(ytTotalSupplyV2));

          await getInfoFromServer();

          stakedAmount = await getStakeAmount("0");
          setMyStakedPAN(stakedAmount);
          
          totalPANRewards = await getTotalPANRewards();
          setMyPANRewards(totalPANRewards);

          //if ( (!isNaN(calReserveRatio)) && (Number(calReserveRatio)<150) ) {
          //  setReserveRatio(calReserveRatio);
          //}
          
          //V2
          //if ( (!isNaN(calReserveRatioV2)) && (Number(calReserveRatioV2)<150) ) {
          //  setReserveRatioV2(calReserveRatioV2);
          //}

        } catch (error) {
          console.error(error);
        }
      }
    };
  
    if (web3Provider) {
      updateBalance();
      intervalId = setInterval(updateBalance, 15000);
    }
    else {
    }
  
    return () => clearInterval(intervalId);
  }, [web3Provider, stakedCRO, stakedCROV2, unstakingCRO, unstakingCROV2, flexibleCRO, flexibleCROV2, ytTotalSupply, ytTotalSupplyV2]);
  ///////////////////////

  // Rewards distribution countdown; 23:15;
  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const difference = targetTime - now;

      if (difference <= 0) {
        // Countdown is over, set new target time for the next day
        const newTargetTime = calculateTargetTime();
        setTargetTime(newTargetTime);
        setTimeLeft('00:00:00');
        return;
      }
      
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
      const minutes = Math.floor((difference / (1000 * 60)) % 60).toString().padStart(2, '0');
      const seconds = Math.floor((difference / 1000) % 60).toString().padStart(2, '0');

      setTimeLeft(`${hours}:${minutes}:${seconds}`);
    };

    // Update countdown every second
    const intervalId = setInterval(updateCountdown, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);

  }, [targetTime]);
  ///////////////////////

  return (
    <Box
    className="container"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    height={(isMobile) ? ((web3Provider) ? "88.3vh" : "92.3vh") : "92.3vh"}
    css={{ 
          overflowY: "scroll",
          "&::-webkit-scrollbar": {                    
            width: (isMobile) ? '0px' : '0px',                             
          },       
        }}
    >
    <AccountModal isOpen={isOpenStake} onClose={onCloseStake} />

    <Flex
    alignItems="center"
    justifyContent="space-evenly"
    flexDirection={(isMobile) ? "column" : "row"}
    >
      <Card 
          position="relative"
          top="100px"
          width={(isMobile) ? "85%" : "20%"}
          height={(isMobile) ? "" : "100%"}
          bg="rgb(255, 255, 255)"
          borderRadius="0.8rem"
          border="0rem solid rgb(200, 200, 200)"
          mb={(isMobile) ? "3rem" : "0rem"} 
          >
        <CardHeader>

          <Flex alignItems="center" justifyContent="space-between">
            <Heading size='lg' color="#000">Pool: </Heading>
            <CustomSwitchLarge checkedBackground="rgb(52,93,157)" uncheckedBackground="rgb(6,95,70)" isChecked={V1Checked} toggleSwitch={toggleSwitch} />
          </Flex>

        </CardHeader>

        <CardBody>
        <Stack spacing='3'>
          <Box>

            <Flex alignItems="center">
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                {V1Checked ? "V1" : "V2" }&nbsp;
              </Heading>

              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              Total staked CRO: 
              </Heading>
              <ExternalLinkIcon boxSize={5} color="gray" ml="0.3rem" style={{ cursor: 'pointer' }} onClick={onOpenStake}/>
            </Flex>

            <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {V1Checked ? Number(stakedCRO).toLocaleString("en-US", {minimumFractionDigits: 3}) : Number(stakedCROV2).toLocaleString("en-US", {minimumFractionDigits: 3}) }
            </Text>
          </Box>
          <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

          <Box>

            <Flex alignItems="center">
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                {V1Checked ? "V1" : "V2" }&nbsp;
              </Heading>

              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              Pool flexible CRO: 
              </Heading>
              <ExternalLinkIcon boxSize={5} color="gray" ml="0.3rem" style={{ cursor: 'pointer' }} 
                                onClick={() => V1Checked ? handleExplorer( ("/address/"+Factory_ADDRESS) ) : handleExplorer( ("/address/"+FactoryV2_ADDRESS) ) }/>
            </Flex>

            <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {V1Checked ? Number(flexibleCRO).toLocaleString("en-US", {minimumFractionDigits: 3}) : Number(flexibleCROV2).toLocaleString("en-US", {minimumFractionDigits: 3}) }
            </Text>

          </Box>

          <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

          <Box>

            <Flex alignItems="center">
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                {V1Checked ? "V1" : "V2" }&nbsp;
              </Heading>

              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              Total unstaking CRO: 
              </Heading>
              <ExternalLinkIcon boxSize={5} color="gray" ml="0.3rem" style={{ cursor: 'pointer' }} onClick={onOpenStake}/>
            </Flex>                

            <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {V1Checked ? Number(unstakingCRO).toLocaleString("en-US", {minimumFractionDigits: 3}) : Number(unstakingCROV2).toLocaleString("en-US", {minimumFractionDigits: 3}) }
            </Text>

          </Box>

          <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>
          <Box>
            <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
            Real staking yield: 
            </Heading>
            <Text pt='2'fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {V1Checked ? aprCRO : aprV2CRO }%
            </Text>
          </Box>                    
          <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

          <Box>

            <Flex alignItems="center">
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                {V1Checked ? "V1" : "V2" }&nbsp;
              </Heading>
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                Delegation Ratio: 
              </Heading>
            </Flex>

            <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {V1Checked ? delegationRaio : delegationRaioV2 }%
            </Text>

          </Box>

          <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

          <Box>
            
            <Tooltip label="(staked CRO + unstaking CRO + flexible CRO) / minted ptCRO">

              <Flex alignItems="center">
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                  {V1Checked ? "V1" : "V2" }&nbsp;
                </Heading>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)"} >
                CRO Reserve Ratio:
                </Heading>
              </Flex>

            </Tooltip>

            <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {V1Checked ? reserveRatio : reserveRatioV2 }%
            </Text>

          </Box>                    

          <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>
        </Stack>
        </CardBody>
      </Card>

      <Card 
          position="relative"
          top="100px"
          width={(isMobile) ? "85%" : "20%"}
          height={(isMobile) ? "" : "100%"}
          bg="rgb(255, 255, 255)"
          borderRadius="0.8rem"
          border="0rem solid rgb(200, 200, 200)"
          mb={(isMobile) ? "3rem" : "0rem"} 
          >
        <CardHeader>

          <Flex alignItems="center" justifyContent="space-between">
            <Heading size='lg' color="#000">Balance: </Heading>
            <CustomSwitchLarge checkedBackground="rgb(52,93,157)" uncheckedBackground="rgb(6,95,70)" isChecked={V1Checked} toggleSwitch={toggleSwitch} />
          </Flex>

        </CardHeader>

        <CardBody>
          <Stack spacing='3'>

            <Box>
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              Next reward distribution: 
              </Heading>

              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {timeLeft}
              </Text>
            </Box>
            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

            <Box>
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              CRO: 
              </Heading>
              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {Number(balanceCRO).toLocaleString("en-US", {minimumFractionDigits: 3})}
              </Text>
            </Box>
            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

            <Box>

              <Flex alignItems="center">
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                WCRO: 
                </Heading>

                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }style={{ cursor: 'pointer' }}
                      onClick={ () => handleCopyClick("WCRO", WCRO_address) } >&nbsp;address</Text>

                <IconButton
                  aria-label="Copy Address"
                  borderRadius="1.25rem"
                  icon={<CopyIcon />}
                  bg="transparent"
                  color="gray"
                  _hover={{ bg: "rgb(200,200,200)" }}
                  size="sm"
                  ml={0.5}
                  onClick={ () => handleCopyClick("WCRO", WCRO_address) }
                />

              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {Number(balanceWCRO).toLocaleString("en-US", {minimumFractionDigits: 3})}
              </Text>
            </Box>
            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

            <Box>

              <Flex alignItems="center">
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                ptCRO
                </Heading>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                &nbsp;{V1Checked ? "V1" : "V2" }:
                </Heading>

                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" } 
                      style={{ cursor: 'pointer' }}
                      onClick={ () => (V1Checked) ? handleCopyClick("ptCRO V1", ptCRO_ADDRESS) : handleCopyClick("ptCRO V2", ptCROV2_ADDRESS) } > 
                &nbsp;address
                </Text>

                <IconButton
                  aria-label="Copy Address"
                  borderRadius="1.25rem"
                  icon={<CopyIcon />}
                  bg="transparent"
                  color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                  _hover={{ bg: "rgb(200,200,200)" }}
                  size="sm"
                  ml={0.5}
                  onClick={ () => (V1Checked) ? handleCopyClick("ptCRO V1", ptCRO_ADDRESS) : handleCopyClick("ptCRO V2", ptCROV2_ADDRESS) }
                />
                
              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
                {V1Checked ? Number(balanceptCRO).toLocaleString("en-US", {minimumFractionDigits: 3}) : Number(balanceptCROV2).toLocaleString("en-US", {minimumFractionDigits: 3}) }
              </Text>

            </Box>

            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

            <Box>

              <Flex alignItems="center">
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                ytCRO
                </Heading>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                &nbsp;{V1Checked ? "V1" : "V2" }:
                </Heading>

                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                      style={{ cursor: 'pointer' }}
                      onClick={ () => (V1Checked) ? handleCopyClick("ytCRO V1", ytCRO_ADDRESS) : handleCopyClick("ytCRO V2", ytCROV2_ADDRESS) } >
                &nbsp;address
                </Text>

                <IconButton
                  aria-label="Copy Address"
                  borderRadius="1.25rem"
                  icon={<CopyIcon />}
                  bg="transparent"
                  color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                  _hover={{ bg: "rgb(200,200,200)" }}
                  size="sm"
                  ml={0.5}
                  onClick={ () => (V1Checked) ? handleCopyClick("ytCRO V1", ytCRO_ADDRESS) : handleCopyClick("ytCRO V2", ytCROV2_ADDRESS) }
                />

              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
                {V1Checked ? Number(balanceytCRO).toLocaleString("en-US", {minimumFractionDigits: 3}) : Number(balanceytCROV2).toLocaleString("en-US", {minimumFractionDigits: 3}) }
              </Text>
            
            </Box>

            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

            <Box>

              <Flex alignItems="center">

                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                  {V1Checked ? "V1" : "V2" }&nbsp;
                </Heading>                          
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                Pending CRO rewards: 
                </Heading>

              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
                {V1Checked ? Number(pendingRewards).toLocaleString("en-US", {minimumFractionDigits: 3}) : Number(pendingRewardsV2).toLocaleString("en-US", {minimumFractionDigits: 3})}
              </Text>

            </Box>

            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

          </Stack>
        </CardBody>
      </Card>

      <Card 
          position="relative"
          top="100px"
          width={(isMobile) ? "85%" : "20%"}
          height={(isMobile) ? "" : "100%"}
          bg="rgb(255, 255, 255)"
          borderRadius="0.8rem"
          border="0rem solid rgb(200, 200, 200)"
          mb={(isMobile) ? "3rem" : "0rem"} 
          >
        <CardHeader>
          <Heading size='lg' color="#000">PAN: </Heading>
        </CardHeader>

        <CardBody>
          <Stack spacing='3'>
            <Box>

              <Flex alignItems="center">
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                Balance: 
                </Heading>

                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" } style={{ cursor: 'pointer' }}
                      onClick={ () => handleCopyClick("PAN", PAN_address) } >&nbsp;address</Text>

                <IconButton
                  aria-label="Copy Address"
                  borderRadius="1.25rem"
                  icon={<CopyIcon />}
                  bg="transparent"
                  color="gray"
                  _hover={{ bg: "rgb(200,200,200)" }}
                  size="sm"
                  ml={0.5}
                  onClick={ () => handleCopyClick("PAN", PAN_address) }
                />

              </Flex>

              <Flex pt='1' alignItems="center">

                <Text fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
                  {Number(balancePAN).toLocaleString("en-US", {minimumFractionDigits: 3})}
                </Text>
                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color="gray.500">
                  &nbsp;${Number(Number(balancePAN)*Number(price_PAN)*Number(price_WCRO)).toLocaleString("en-US", {minimumFractionDigits: 3})}
                </Text>

              </Flex>

            </Box>
            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>
            <Box>
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              Staked:
              </Heading>

              <Flex pt='1' alignItems="center">

                <Text fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
                  {Number(myStakedPAN).toLocaleString("en-US", {minimumFractionDigits: 3})}
                </Text>
                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color="gray.500">
                  &nbsp;${Number(Number(myStakedPAN)*Number(price_PAN)*Number(price_WCRO)).toLocaleString("en-US", {minimumFractionDigits: 3})}
                </Text>

              </Flex>
            </Box>
            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>
            <Box>
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              Pending rewards:
              </Heading>
              <Flex pt='1' alignItems="center">

              <Text fontSize={(isMobile) ? '16x' : '20px'} fontWeight="bold">
                {Number(myPANRewards).toLocaleString("en-US", {minimumFractionDigits: 3})}
              </Text>
              <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color="gray.500">
                  &nbsp;${Number(Number(myPANRewards)*Number(price_PAN)*Number(price_WCRO)).toLocaleString("en-US", {minimumFractionDigits: 3})}
              </Text>

              </Flex>
            </Box>
            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>
            <Box>
              <Heading size='md' color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              Hold:
              </Heading>
              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              Level {panHodlLevel}
              </Text>
            </Box>
            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>
          </Stack>
        </CardBody>
      </Card>

      <Card 
          position="relative"
          top="100px"
          width={(isMobile) ? "85%" : "20%"}
          height={(isMobile) ? "" : "100%"}
          bg="rgb(255, 255, 255)"
          borderRadius="0.8rem"
          border="0em solid rgb(200, 200, 200)"
          mb={(isMobile) ? "3rem" : "0rem"} 
          >
        <CardHeader>

          <Flex alignItems="center" justifyContent="space-between">
            <Heading size='lg' color="#000">Prices and Supply: </Heading>
            <CustomSwitchLarge checkedBackground="rgb(52,93,157)" uncheckedBackground="rgb(6,95,70)" isChecked={V1Checked} toggleSwitch={toggleSwitch} />
          </Flex>

        </CardHeader>

        <CardBody>
          <Stack spacing='3'>

            <Box>
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              1 PAN: 
              </Heading>
              <Flex pt='1' alignItems="center">
                <Text fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
                {Number(price_PAN).toLocaleString("en-US", {minimumFractionDigits: 3})} CRO
                </Text>
                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color="gray.500">
                &nbsp;${Number(Number(price_PAN)*Number(price_WCRO)).toLocaleString("en-US", {minimumFractionDigits: 3})}
                </Text>
              </Flex>
            </Box>

            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

            <Box>

              <Flex>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                1 ptCRO
                </Heading>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                  &nbsp;{V1Checked ? "V1" : "V2" }:
                </Heading>
              </Flex>

              <Flex pt='1' alignItems="center">
                <Text fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
                {V1Checked ? Number(price_ptCRO).toLocaleString("en-US", {minimumFractionDigits: 3})
                           : Number(price_ptCROV2).toLocaleString("en-US", {minimumFractionDigits: 3}) }CRO
                </Text>
                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color="gray.500">
                &nbsp;$
                {V1Checked ? Number(Number(price_ptCRO)  *Number(price_WCRO)).toLocaleString("en-US", {minimumFractionDigits: 3})
                           : Number(Number(price_ptCROV2)*Number(price_WCRO)).toLocaleString("en-US", {minimumFractionDigits: 3}) }
                </Text>
              </Flex>

            </Box>

            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

            <Box>
              <Flex>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                1 ytCRO:
                </Heading>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                  &nbsp;{V1Checked ? "V1" : "V2" }:
                </Heading>
              </Flex>

              <Flex pt='1' alignItems="center">
                <Text fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
                {V1Checked ? Number(price_ytCRO).toLocaleString("en-US", {minimumFractionDigits: 3})
                           : Number(price_ytCROV2).toLocaleString("en-US", {minimumFractionDigits: 3}) }CRO
                </Text>
                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color="gray.500">
                &nbsp;$
                {V1Checked ? Number(Number(price_ytCRO)  *Number(price_WCRO)).toLocaleString("en-US", {minimumFractionDigits: 3})
                           : Number(Number(price_ytCROV2)*Number(price_WCRO)).toLocaleString("en-US", {minimumFractionDigits: 3}) }
                </Text>
              </Flex>
              
            </Box>

            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

            <Box>
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              1 WCRO:
              </Heading>
              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              ${Number(price_WCRO).toLocaleString("en-US", {minimumFractionDigits: 3})}
              </Text>
            </Box>

            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

            <Box>
              <Flex>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                Total supply of ptCRO/ytCRO
                </Heading>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                  &nbsp;{V1Checked ? "V1" : "V2" }:
                </Heading>
              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {V1Checked ? Number(ytTotalSupply).toLocaleString("en-US", {minimumFractionDigits: 3})
                         : Number(ytTotalSupplyV2).toLocaleString("en-US", {minimumFractionDigits: 3}) }
              </Text>
            </Box>

            <Divider borderColor="black" borderWidth="1px" borderRadius="3rem"/>

          </Stack>
        </CardBody>
      </Card>

      {(isMobile) && (
      <Flex w="30px" h="5rem">
      </Flex>
      )}

    </Flex>

    
    <Flex bottom="0px" justify="space-between" alignItems="center" width="100vw" mb={(isAndroid) ? "1.7rem" : "0.2rem"}>

      <Flex mr="16px" w="90px">
      </Flex>

      <Flex alignItems="center">
        <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
        <Box width="85px" height="30px">
          <Image src={logo_cronos} fit="contain"/>
        </Box>
      </Flex>

      <Flex alignItems="center" w={(web3Provider) ? "" : "90px"}>
        {web3Provider && (
        <Flex alignItems="center">
        <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color={color2} mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
        <CircleIcon boxSize={3} color={color} mr={(isMobile) ? '0px' : '16px'}/>
        </Flex>
        )}
        {!web3Provider && (
        <Flex alignItems="center">
        <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
        <CircleIcon boxSize={(isMobile) ? '2' : '3'} color="transparent" mr={(isMobile) ? '0px' : '16px'}/>
        </Flex>
        )}
      </Flex>

    </Flex>
    
    </Box>
  );
}

export default Dashboard;
